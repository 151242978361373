<template>
  <div class="page-container cbhpm-tables-page">
    <portal to="page-name">Tabelas CBHPM</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Tabelas CBHPM</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" class="form-input" v-model="filter.search"
                     autocomplete="nope" placeholder="Pesquisar...">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/>Nova tabela
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Tabelas CBHPM</p>
              <p class="empty-subtitle">
                Nenhuma tabela encontrada. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th class="hide-sm" style="width:40px">#</th>
            <th>Nome</th>
            <th>Edição</th>
            <th>Tabela de cálculo</th>
            <th class="text-right">UCO</th>
            <th class="text-center">Coeficiente</th>
            <th class="hide-sm hide-md text-center">Ativo</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data.items" :key="i">
            <td class="hide-sm">{{ i + 1 }}</td>
            <td>
              <router-link :to="editLink(item)">{{ item.name }}</router-link>
            </td>
            <td>{{ item.edition.name }}</td>
            <td>{{ item.calculation.name }}</td>
            <td class="text-right">{{ item.operationalCost | currency }}</td>
            <td class="text-center">
              <span v-if="item.coefficient && item.coefficient !== 0">
                {{ item.coefficient | number }}
              </span>
              <small v-else>Não aplicado</small>
            </td>
            <td class="hide-sm hide-md text-center">{{ item.active ? 'Sim' : 'Não' }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                      @click="edit(item)" data-tooltip="Editar">
                <fa-icon :icon="['fal', 'pencil']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
          </template>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: '/cbhpm-tables',
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      filter: {
        search: '',
      },
      data: {
        items: [],
        hasMore: false,
        limit: 20,
        offset: 0,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 1000);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    create() {
      this.$router.push(`/financial${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`/financial${this.path}/${item.id}/edit`);
    },
    editLink(item) {
      return `/financial${this.path}/${item.id}/edit`;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .cbhpm-tables-page {
  }
</style>
